// Here you can add other styles
.default-layout-body {
  margin-top: 3rem;
}

.default-header-btn {
  margin-right: 1rem;
}

.jumbotron-event {
  background-color: #ffffff;
  margin-top: 1rem;
  border-radius: 3%;
}

.events-header {
  font-weight: 400;
}

.event-img {
  border-radius: 3%;
}

.center-align {
  text-align: center;
}

@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    left: 17%;
  }

  .footer-item {
    width: 8rem;
  }
}
